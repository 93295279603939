'use strict';

var setVideoCenter = function () {
    var $box = $('.mainbanner-container');
    var videoBox = $('.video-component').find('iframe');
    var width = $box.width();
    var height = $box.height();
    var newHeight = width / 1.78;

    if (newHeight > height) {
        videoBox.css({
            width: width,
            height: newHeight
        });
    } else {
        var newWidth = height * 1.78;

        videoBox.css({
            width: newWidth,
            height: height
        });
    }
};

$(document).ready(function () {
    setVideoCenter();
    $(window).resize(setVideoCenter);
});
